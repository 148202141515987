import { Statuses } from '@context/global';
import {
  AUTOFULFILLMENT_MODES,
  INVOICE_FREQUENCYS,
  LOCALES,
  MerchantAdmin,
  PAYOR_MODES,
  PLATFORMS,
  Platform,
  SETUP_STEPS,
  UNIT_SYSTEMS
} from '@ecocart/entities';
import { useMerchantStatusConfigs } from '@hooks/useMerchantStatusConfigs';
import { useQuery } from '@tanstack/react-query';
import { getPaymentMethods, hasAtLeastOnePaymentMethod, hasAtLeastOneValidPaymentMethod } from '@utils/api/payments';
import { ApiMerchantAdminFields, GlobalMerchantAdminFields, ShopifyMerchantAdminFields } from '@utils/constants/merchant-admin-fields';
import { ValidationFieldNames } from '@utils/validation';
import { FormFieldsConfig } from '../shared/EcoForm';

// TODO: Evaluation adding back 'flatRateCartAmount' to MerchantSettingsValidationFieldNames
// TODO: Context - we have grandfathered in deals that are beyond current min/max, so disable for now
// TODO: Note: validation still exists on merchant's side aka EditEcoCartSettingsForm
export const MerchantSettingsValidationFieldNames: ValidationFieldNames[] = [
  'defaultItemWeightKg',
  'ecoIncentivesCartValueThreshold',
  'ecoIncentivesNumberOfTrees',
  'offsetMultiplier',
  'ecoRewardsPercentage',
  'claimFormUrl',
  'cashbackPercentage',
  'cashbackFlatRateAmount'
];

const ConfigMap: Record<Platform, string[]> = {
  Shopify: ShopifyMerchantAdminFields,
  Shopify_headless: ShopifyMerchantAdminFields,
  Wix: GlobalMerchantAdminFields,
  WooCommerce: GlobalMerchantAdminFields,
  BigCommerce: GlobalMerchantAdminFields,
  Custom: GlobalMerchantAdminFields,
  api: ApiMerchantAdminFields
};

export function useFormFieldsConfig(merchantAdmin?: MerchantAdmin): FormFieldsConfig[] {
  const { shopName, ecocartActive, variantIds } = merchantAdmin || {};
  const { data: paymentMethods } = useQuery(['getPaymentMethods', shopName], () => getPaymentMethods(shopName), { enabled: !!shopName });

  const statuses: Statuses = {
    areVariantsCreated: !!variantIds?.[0],
    hasAValidPaymentMethod: hasAtLeastOneValidPaymentMethod(paymentMethods),
    isBillingEnabled: hasAtLeastOnePaymentMethod(paymentMethods),
    isOnboardingComplete: merchantAdmin?.isOnboardingComplete || true
  };
  const isEcocartActiveDisabled =
    (!ecocartActive && !statuses.areVariantsCreated && merchantAdmin?.platform !== 'api') || !statuses.isBillingEnabled;
  const { getFormTooltipConfig } = useMerchantStatusConfigs({ merchantAdmin, statuses });

  const formFieldsConfig: FormFieldsConfig<Partial<MerchantAdmin>>[] = [
    {
      field: 'ecocartActive',
      label: 'Enable EcoCart',
      type: 'toggle',
      disabled: isEcocartActiveDisabled,
      tooltipConfig: { ...getFormTooltipConfig('ecocart_active'), placement: 'left' }
    },
    {
      field: 'ecocartEnabled',
      label: 'Show Widgets on Store',
      type: 'toggle',
      disabled: !ecocartActive,
      tooltipConfig: { ...getFormTooltipConfig('widgets_visible'), placement: 'left' }
    },
    {
      field: 'companyName'
    },
    {
      field: 'ecoCalculationApiKey',
      disabled: true
    },
    {
      field: 'accessToken',
      disabled: true
    },
    {
      field: 'shopifyAccessTokenIsValid',
      disabled: true,
      type: 'checkbox'
    },
    {
      field: 'shopifyStorefrontAccessToken'
    },
    {
      field: 'rechargeAccessToken'
    },
    {
      field: 'rechargeProductId'
    },
    {
      field: 'enableEcoRewards',
      type: 'checkbox'
    },
    {
      field: 'chargeDiscountedAmountEnabled',
      type: 'checkbox'
    },
    {
      field: 'eventTrackingEnabled',
      type: 'checkbox'
    },
    {
      field: 'ecoRewardsPercentage'
    },
    {
      field: 'isTestStore',
      type: 'checkbox'
    },
    {
      field: 'isBillingDisabled',
      disabled: true,
      type: 'checkbox'
    },
    {
      field: 'autofulfillmentMode',
      type: 'picker',
      items: AUTOFULFILLMENT_MODES
    },
    {
      field: 'platform',
      type: 'picker',
      items: PLATFORMS
    },
    {
      field: 'payorMode',
      type: 'picker',
      items: PAYOR_MODES
    },
    {
      field: 'shippingRateEnabled',
      type: 'checkbox'
    },
    {
      field: 'merchantPayingThresholdAmount'
    },
    {
      field: 'optOut',
      type: 'checkbox',
      label: 'Cart Opt Out'
    },
    {
      field: 'checkoutOptOut',
      type: 'checkbox',
      label: 'Checkout Opt Out'
    },
    {
      field: 'locale',
      type: 'picker',
      items: LOCALES
    },
    {
      field: 'unitSystem',
      type: 'picker',
      items: UNIT_SYSTEMS
    },
    {
      field: 'setupStep',
      type: 'picker',
      items: SETUP_STEPS
    },
    {
      field: 'invoiceFrequency',
      type: 'picker',
      items: INVOICE_FREQUENCYS
    },
    {
      field: 'siteUrl'
    },
    {
      field: 'logoUrl'
    }
  ];

  const platformFormFieldsConfig = formFieldsConfig.filter((field) =>
    ConfigMap[merchantAdmin?.platform || 'Shopify'].includes(field.field)
  );
  return platformFormFieldsConfig;
}
