import { MerchantAdmin } from '@ecocart/entities';
import { GLOBAL_PUBLIC_CONFIG } from '@ecocart/universal-utils';
import { apiCall, PRODUCT_MANAGER_API, PUPPETEER_API, SHOPIFY_NODE_API } from './api';
import { triggerBinPackUpdate } from './bin-pack';
import { updateMerchantAdmin } from './merchant';

/* -------------------------------- shop -------------------------------- */
export const getShop = (shopName: MerchantAdmin['shopName']): Promise<any> => {
  return apiCall('GET', `${SHOPIFY_NODE_API}/shop`, null, { 'X-Eco-ShopName': shopName });
};

export const getShopifyScripts = (shopName: MerchantAdmin['shopName']): Promise<any> => {
  return apiCall('GET', `${SHOPIFY_NODE_API}/shop/scripts`, null, { 'X-Eco-ShopName': shopName });
};

export const addEcoCartScript = (shopName: MerchantAdmin['shopName']): Promise<any> => {
  return apiCall('PUT', `${SHOPIFY_NODE_API}/shop/scripts/ecocart`, null, { 'X-Eco-ShopName': shopName });
};

export const removeEcoCartScript = (shopName: MerchantAdmin['shopName']): Promise<any> => {
  return apiCall('DELETE', `${SHOPIFY_NODE_API}/shop/scripts/ecocart`, null, { 'X-Eco-ShopName': shopName });
};

export const getShopCheckoutLiquidAsset = (shopName: MerchantAdmin['shopName']): Promise<any> => {
  return apiCall('GET', `${SHOPIFY_NODE_API}/shop/assets/checkout`, null, { 'X-Eco-ShopName': shopName });
};

export const updateShopCheckoutLiquidAsset = (shopName: MerchantAdmin['shopName'], html: string): Promise<any> => {
  return apiCall('PUT', `${SHOPIFY_NODE_API}/shop/assets/checkout`, { html }, { 'X-Eco-ShopName': shopName });
};

export const getShopifyDiagnostics = (shopName: MerchantAdmin['shopName']): Promise<any> => {
  return apiCall('POST', `${PUPPETEER_API}/shopify/diagnostics`, { shopName });
};

export const getShopifyScopes = (shopName: MerchantAdmin['shopName']): Promise<any> => {
  return apiCall('GET', `${SHOPIFY_NODE_API}/shop/scopes`, null, { 'X-Eco-ShopName': shopName });
};

export const initializeMerchant = (shopName: MerchantAdmin['shopName']): Promise<any> => {
  return apiCall('POST', `${SHOPIFY_NODE_API}/shop/initialize`, null, { 'X-Eco-ShopName': shopName });
};

interface SessionResponse {
  shopName: '';
  accessToken: '';
  systemVersion: 'v1' | 'v1.5' | 'v2';
  requiresReauthentication: boolean;
}

interface Session {
  shopName: string;
  session?: string;
  hmac?: string;
  timestamp?: string;
  host?: string;
}

export class ShopifyProduct {
  identifier: string = '';
  title: string = '';
  description: string = '';
  main_image_url: string = '';
  main_image: Record<string, string> = {}; // for update
  store_product_id: string = '';
}

export const getShopifySession = ({ shopName, session, hmac, timestamp, host }: Session): Promise<SessionResponse> => {
  return apiCall<SessionResponse>(
    'POST',
    `${SHOPIFY_NODE_API}/shop/session`,
    {
      shop: shopName,
      session,
      hmac,
      timestamp,
      host
    },
    {
      'X-Eco-PubKey': GLOBAL_PUBLIC_CONFIG.X_ECO_PUB_KEY
    }
  );
};

export const updateShopifyVariantSku = (shopName: MerchantAdmin['shopName'], sku: string): Promise<any> => {
  return apiCall<any>('PUT', `${PRODUCT_MANAGER_API}/product/variants/${shopName}`, { sku }).then(() => {
    return updateMerchantAdmin(shopName, { sku });
  });
};

export const regenerateShopifyVariants = (
  shopName: MerchantAdmin['shopName'],
  shopifyAccessToken: MerchantAdmin['accessToken'],
  manufacturingCalculationType: MerchantAdmin['manufacturingCalculationType']
): Promise<any> => {
  return apiCall<any>('DELETE', `${PRODUCT_MANAGER_API}/delete-store-variants/${shopName}`, null, {
    'X-Eco-Shopify-Access-Token': shopifyAccessToken
  }).then(() => triggerBinPackUpdate(shopName, manufacturingCalculationType));
};

export const shouldTriggerProductNameUpdate = (merchantAdmin: Partial<MerchantAdmin>, dbMerchantAdmin: Partial<MerchantAdmin>): boolean => {
  const updateProp: keyof MerchantAdmin = 'offsetMultiplier';
  return !!merchantAdmin[updateProp] && dbMerchantAdmin[updateProp] !== merchantAdmin[updateProp];
};

export const triggerProductNameUpdate = (
  shopName: MerchantAdmin['shopName'],
  offsetMultiplier: MerchantAdmin['offsetMultiplier'],
  shopifyAccessToken: MerchantAdmin['accessToken']
): Promise<void> => {
  if (!shopName) return new Promise((resolve) => resolve(undefined as any));

  const title = offsetMultiplier > 1 ? 'Climate Positive Order' : 'Carbon Neutral Order';

  return apiCall('PUT', `${PRODUCT_MANAGER_API}/product/${shopName}`, { title }, { 'X-Eco-Shopify-Access-Token': shopifyAccessToken });
};

export const getCurrentProduct = (
  shopName: MerchantAdmin['shopName'],
  shopifyAccessToken: MerchantAdmin['accessToken']
): Promise<ShopifyProduct | undefined> => {
  if (!shopName) return new Promise((resolve) => resolve(undefined as any));

  return apiCall<Record<'products', ShopifyProduct[]>>('GET', `${PRODUCT_MANAGER_API}/product/${shopName}`, null, {
    'X-Eco-Shopify-Access-Token': shopifyAccessToken
  }).then((res) => {
    return res?.products?.[0];
  });
};

export const updateProduct = (shopName: MerchantAdmin['shopName'], payload: Partial<ShopifyProduct>): Promise<ShopifyProduct> => {
  return apiCall('PUT', `${PRODUCT_MANAGER_API}/product/${shopName}`, payload);
};
