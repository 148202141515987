import { EcoTab, EcoTabContent, EcoTabs } from '@components/shared';
import EcoScrollView from '@components/views/EcoScrollView';
import { useMerchantSetupConfig } from '@hooks/useMerchantSetupConfig';
import { BackLink } from '@navigation/BackLink';
import { GlobalScreenProps } from '@navigation/types';
import { useQuery } from '@tanstack/react-query';
import { getMerchant, getMerchantAdmin } from '@utils/api/merchant';
import { getCurrentProduct } from '@utils/api/shopify';
import { EcoCartApiMerchantTabs, EcoCartGlobalMerchantTabs } from '@utils/constants/tabs';
import { Gap } from '@utils/layout';
import React from 'react';
import { View } from 'react-native';

export default function MerchantSetupScreen({ route }: GlobalScreenProps): JSX.Element {
  const shopName = route?.params?.shopName;
  const { data: merchant, isLoading: merchantIsLoading } = useQuery(['merchant', shopName], () => getMerchant(shopName));
  const { data: merchantAdmin, refetch } = useQuery(['merchantAdmin', shopName], () => getMerchantAdmin(shopName));
  const {
    data: product,
    isLoading: productIsLoading,
    refetch: productRefetch
  } = useQuery(['getCurrentProduct', merchantAdmin?.shopName, merchantAdmin?.accessToken], () =>
    getCurrentProduct(merchantAdmin?.shopName || '', merchantAdmin?.accessToken || '')
  );
  const EcoCartMerchantTabs = merchantAdmin?.platform === 'api' ? EcoCartApiMerchantTabs : EcoCartGlobalMerchantTabs;
  const formConfig = useMerchantSetupConfig({
    shopName,
    merchant,
    merchantIsLoading,
    merchantAdmin,
    refetch,
    product,
    productIsLoading,
    productRefetch
  });

  return (
    <EcoScrollView>
      <View className="flex-row">
        <BackLink href="/merchants" label="Back to Merchants" />
      </View>
      <EcoTabs
        tabs={EcoCartMerchantTabs.map(({ label, screenName }) => {
          return {
            label,
            screenName,
            screenParams: { shopName }
          } as EcoTab;
        })}
        prefix="Merchant"
        activeScreen="MerchantSetup"
      >
        <EcoTabContent>
          <View style={Gap()}>
            {formConfig.map(({ formName, component }) => (
              <View key={formName}>{component}</View>
            ))}
          </View>
        </EcoTabContent>
      </EcoTabs>
    </EcoScrollView>
  );
}
