import { MerchantAdmin } from '@ecocart/entities';

export const GlobalMerchantActivityCard = ['Last Updated', 'Initial Install', 'Store Variants', 'EcoCart', 'Widgets'];
export const ApiMerchantActivityCard = ['Last Updated', 'Initial Install', 'EcoCart'];

export const GlobalMerchantAdminFields: (keyof MerchantAdmin)[] = [
  'ecocartActive',
  'ecocartEnabled',
  'companyName',
  'ecoCalculationApiKey',
  'accessToken',
  'shopifyAccessTokenIsValid',
  'shopifyStorefrontAccessToken',
  'rechargeAccessToken',
  'rechargeProductId',
  'defaultItemWeightKg',
  'enableEcoRewards',
  'eventTrackingEnabled',
  'ecoRewardsPercentage',
  'ecoIncentivesEnabled',
  'ecoIncentivesCartValueThreshold',
  'ecoIncentivesNumberOfTrees',
  'offsetMultiplier',
  'pricingMode',
  'flatRateMode',
  'flatRatePercentage',
  'flatRateLineItemAmount',
  'flatRateCartAmount',
  'isTestStore',
  'isBillingDisabled',
  'autofulfillmentMode',
  'enableManufacturing',
  'enableShipping',
  'platform',
  'payorMode',
  'merchantPayingThresholdAmount',
  'shippingRateEnabled',
  'optOut',
  'checkoutOptOut',
  'locale',
  'unitSystem',
  'setupStep',
  'invoiceFrequency',
  'siteUrl',
  'logoUrl'
];

export const ShopifyMerchantAdminFields: (keyof MerchantAdmin)[] = [
  'ecocartActive',
  'ecocartEnabled',
  'companyName',
  'accessToken',
  'shopifyAccessTokenIsValid',
  'shopifyStorefrontAccessToken',
  'rechargeAccessToken',
  'rechargeProductId',
  'defaultItemWeightKg',
  'enableEcoRewards',
  'eventTrackingEnabled',
  'ecoRewardsPercentage',
  'chargeDiscountedAmountEnabled',
  'ecoIncentivesEnabled',
  'ecoIncentivesCartValueThreshold',
  'ecoIncentivesNumberOfTrees',
  'offsetMultiplier',
  'pricingMode',
  'flatRateMode',
  'flatRatePercentage',
  'flatRateLineItemAmount',
  'flatRateCartAmount',
  'isTestStore',
  'isBillingDisabled',
  'autofulfillmentMode',
  'enableManufacturing',
  'enableShipping',
  'platform',
  'payorMode',
  'merchantPayingThresholdAmount',
  'shippingRateEnabled',
  'optOut',
  'checkoutOptOut',
  'locale',
  'unitSystem',
  'setupStep',
  'invoiceFrequency',
  'siteUrl',
  'logoUrl'
];

export const ApiMerchantAdminFields: (keyof MerchantAdmin)[] = [
  'ecocartActive',
  'companyName',
  'ecoCalculationApiKey',
  'defaultItemWeightKg',
  'enableEcoRewards',
  'ecoRewardsPercentage',
  'offsetMultiplier',
  'pricingMode',
  'flatRateMode',
  'flatRatePercentage',
  'flatRateLineItemAmount',
  'flatRateCartAmount',
  'isTestStore',
  'isBillingDisabled',
  'enableManufacturing',
  'enableShipping',
  'platform',
  'payorMode',
  'locale',
  'unitSystem',
  'invoiceFrequency',
  'siteUrl',
  'logoUrl'
];
