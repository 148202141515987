import { MerchantAdmin } from '@ecocart/entities';
import { apiCall } from './api';

export interface ShipInsureMerchant {
  merchant_uuid: string;
  name: string;
  email_address: string;
  domain_name: string;
  website_url: string;
  shopify_access_token: string;
  product_id: string;
  platform: string;
  shopify_id: string;
}

export const syncMerchant = (shopName: string, productId: string, shopifyStoreId: string): Promise<MerchantAdmin> => {
  if (!shopName) return new Promise((resolve) => resolve(undefined as any));
  return apiCall<MerchantAdmin>('POST', '/shipinsure', {
    shopName,
    productId,
    shopifyStoreId
  });
};

export const getShipInsureMerchant = (shopName: string): Promise<ShipInsureMerchant> => {
  if (!shopName) return new Promise((resolve) => resolve(undefined as any));
  return apiCall<ShipInsureMerchant>('GET', `/shipinsure/${shopName}`);
};
