import { EcoButton, EcoImage, EcoInput, EcoLoader, EcoText } from '@components/shared';
import { EcoFilePicker } from '@components/shared/EcoFilePicker';
import { MerchantAdmin } from '@ecocart/entities';
import { useOverlay } from '@hooks/useOverlay';
import { useMutation } from '@tanstack/react-query';
import { ShopifyProduct, updateProduct } from '@utils/api/shopify';
import { colors } from '@utils/tailwind';
import { DocumentResult } from 'expo-document-picker';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { View } from 'react-native';

interface Props {
  merchantAdmin: MerchantAdmin;
  product: ShopifyProduct | undefined;
  isLoading: boolean;
  refetch: () => void;
}

export default function EditShopifyProductForm({ merchantAdmin, product, isLoading, refetch }: Props): JSX.Element | null {
  const { showSuccess, showWarning } = useOverlay();
  const [newImage, setNewImage] = useState<Record<string, string>>({ uri: '', name: '', mimeType: '' });

  const { mutate, isLoading: isSubmitting } = useMutation<ShopifyProduct, Error, { shopName: string; payload: Partial<ShopifyProduct> }>(
    ({ shopName, payload }) => updateProduct(shopName, payload),
    {
      onSuccess: () => {
        showSuccess('Success! Shopify Product has been updated successfully.');
        refetch();
      },
      onError: (error: Error) => {
        showWarning(`An error occurred while updating Shopify Product. ${error.message}`);
      }
    }
  );

  const onSubmit = async ({ title, description }: Partial<ShopifyProduct>) => {
    const payload: Partial<ShopifyProduct> = {
      title: title || '',
      description: description || ''
    };

    if (newImage.uri) {
      const stringTobeReplaced = `data:${newImage.mimeType};base64,`;
      payload.main_image = {
        file_name: newImage.name,
        file_data: newImage.uri.replace(stringTobeReplaced, '')
      };
    }

    mutate({ shopName: merchantAdmin.shopName, payload });
  };

  const handlePick = (result: DocumentResult) => {
    if (result.type === 'success') {
      setNewImage({ uri: result.uri, name: result.name, mimeType: result.mimeType || '' });
    }
  };

  const handleReset = () => {
    setNewImage({ uri: '', name: '', mimeType: '' });
  };

  return isLoading ? (
    <EcoLoader size="large" className="py-9" color={colors.primary[400]} />
  ) : (
    <Formik initialValues={product || new ShopifyProduct()} onSubmit={onSubmit}>
      {({ handleChange, handleSubmit, values, isValid }) => (
        <>
          <View>
            <EcoText color="subdued" fontSize="sm" className="py-1">
              Image
            </EcoText>
            <EcoImage src={newImage.uri || values.main_image_url} width={175} resizeMode="contain" />
            <View className="flex flex-row mt-2">
              <EcoFilePicker
                onPick={handlePick}
                label="Change"
                variant="outlined"
                size="sm"
                isDisabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
              />
              <EcoButton
                size="sm"
                variant="ghost"
                className="w-max ml-2"
                isDisabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
                onPress={handleReset}
              >
                Restore Default Image
              </EcoButton>
            </View>
          </View>
          <View className="flex flex-col">
            <EcoInput
              label="Title"
              value={values.title}
              field="title"
              onChangeText={handleChange('title')}
              placeholder="Title"
              disabled={isSubmitting}
            />
            <EcoInput
              label="Description"
              value={values.description}
              field="description"
              onChangeText={handleChange('description')}
              placeholder="Description"
              disabled={isSubmitting}
            />
            <EcoButton
              size="sm"
              className="w-max mt-2"
              isDisabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
              onPress={handleSubmit}
            >
              Save
            </EcoButton>
          </View>
        </>
      )}
    </Formik>
  );
}
