import { EcoButton, EcoCard, EcoText } from '@components/shared';
import { MerchantAdmin } from '@ecocart/entities';
import { useOverlay } from '@hooks/useOverlay';
import { useQuery } from '@tanstack/react-query';
import { getShipInsureMerchant, syncMerchant } from '@utils/api/shipinsure';
import { getShop, ShopifyProduct } from '@utils/api/shopify';
import { useEffect, useState } from 'react';
import { View } from 'react-native';

interface Props {
  merchantAdmin: MerchantAdmin | undefined;
  refetch: () => void;
  product: ShopifyProduct | undefined;
}

export default function ShipInsureMerchantCard({ merchantAdmin, refetch, product }: Props): JSX.Element {
  const { data: shop } = useQuery(['getShop', merchantAdmin?.shopName], () => getShop(merchantAdmin?.shopName || ''));
  const { data: shipInsureMerchant, refetch: refetchShipInsureMerchant } = useQuery(
    ['getShipInsureMerchant', merchantAdmin?.shopName],
    () => getShipInsureMerchant(merchantAdmin?.shopName || '')
  );
  const [isSyncRequired, setIsSyncRequired] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const { handleError } = useOverlay();

  useEffect(() => {
    const data = {
      name: merchantAdmin?.companyName,
      email_address: merchantAdmin?.contactEmail,
      domain_name: merchantAdmin?.shopName,
      website_url: merchantAdmin?.shopName,
      shopify_access_token: merchantAdmin?.accessToken,
      product_id: product?.store_product_id || '',
      platform: merchantAdmin?.platform,
      shopify_id: shop?.externalId || ''
    };

    const requiresSync = Object.entries(data).some(([key, value]) => {
      if (!shipInsureMerchant) return true;
      return shipInsureMerchant[key as keyof typeof shipInsureMerchant] !== value;
    });

    setIsSyncRequired(requiresSync);
  }, [product, shop, shipInsureMerchant]);

  const handleSyncMerchantPress = async () => {
    if (!merchantAdmin?.shopName) return;
    setIsSyncing(true);

    try {
      await syncMerchant(merchantAdmin?.shopName, product?.store_product_id || '', shop?.externalId || '');
      refetch();
      refetchShipInsureMerchant();
    } catch (error) {
      handleError(error as any);
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <EcoCard
      title="ShipInsure Merchant"
      topRightComponent={
        <EcoButton isDisabled={isSyncing || !isSyncRequired} size="sm" onPress={handleSyncMerchantPress} isLoading={isSyncing}>
          {isSyncRequired ? 'Sync Merchant' : 'Synced'}
        </EcoButton>
      }
    >
      <View className="flex-row flex-start">
        <EcoText className="w-[120px]" color="subdued">
          Merchant UUID:
        </EcoText>
        <EcoText fontWeight="semibold" color="subdued">
          {shipInsureMerchant?.merchant_uuid || 'Unknown'}
        </EcoText>
      </View>
    </EcoCard>
  );
}
