import { EcoButton, EcoCheckbox, EcoLoader, EcoRadioButtonCards, EcoText, FormButtons } from '@components/shared';
import { hydrateRadioButtonData } from '@components/shared/EcoRadioButtons.utils';
import { MerchantAdmin, PayorMode } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { useWindow } from '@hooks/useWindow';
import { updateMerchantAdmin } from '@utils/api/merchant';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { Gap } from '@utils/layout';
import { logUserEvt } from '@utils/log';
import { GlobalFormProps } from '@utils/prop-types';
import { FontSize } from '@utils/tailwind';
import { CreateValidationSchema } from '@utils/validation';
import clsx from 'clsx';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { View } from 'react-native';
import { EcoHeadings } from '../shared/EcoHeadings';

interface Props extends GlobalFormProps {
  merchantAdmin: MerchantAdmin;
}

// const PRICING_MODES = ['climate_positive', 'carbon_neutral', 'flat_rate'] as const;
// type PricingModes = (typeof PRICING_MODES)[number];

const Headings = ({ title, titleSize = 'lg', subTitle }: { title: string; titleSize?: FontSize; subTitle: string }) => {
  return (
    <View className="mb-4">
      <EcoHeadings title={title} subTitle={subTitle} titleSize={titleSize} subTitleClassName="text-sm text-subdued" />
    </View>
  );
};

type PayorOption = Partial<PayorMode>;
type MatchingOption = Partial<PayorMode> | 'no_matching';

const getPayorOptionModes = (payorMode: PayorMode): { payorOption: PayorOption; matchingOption: MatchingOption } => {
  const isCustomer = payorMode?.startsWith('customer_');
  const isPaying = payorMode?.endsWith('_paying');

  if (isPaying) {
    return {
      payorOption: isCustomer ? 'customer_paying' : 'merchant_paying',
      matchingOption: 'no_matching'
    };
  } else {
    return {
      payorOption: isCustomer ? 'merchant_paying' : 'customer_paying',
      matchingOption: isCustomer ? 'customer_matching' : 'merchant_matching'
    };
  }
};

export default function EditEcoCartSettingsForm({ merchantAdmin, onSuccess }: Props): JSX.Element {
  const { session } = useGlobal();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [payorOption, setPayorOption] = useState<PayorOption>(getPayorOptionModes(merchantAdmin.payorMode).payorOption);
  const [matchingOption, setMatchingOption] = useState<MatchingOption>(getPayorOptionModes(merchantAdmin.payorMode).matchingOption);
  const optOption = merchantAdmin?.optOut ? 'opt_out' : 'opt_in';
  const { handleError, showSuccess } = useOverlay();
  const { isWide } = useWindow();

  const getNormalizedProps = (): Partial<MerchantAdmin> => {
    return {
      payorMode: matchingOption === 'no_matching' ? payorOption : matchingOption,
      optOut: optOption === 'opt_out'
    };
  };

  const onSubmit = async (_merchantAdmin: MerchantAdmin) => {
    setIsSubmitting(true);

    const updatedMerchantAdmin = {
      ..._merchantAdmin,
      ...getNormalizedProps()
    };

    if (updatedMerchantAdmin.pricingMode === 'flat_rate') {
      updatedMerchantAdmin.flatRateMode = 'cart_basis';
      updatedMerchantAdmin.flatRateCartAmount =
        typeof updatedMerchantAdmin.flatRateCartAmount === 'number'
          ? updatedMerchantAdmin.flatRateCartAmount
          : Number(((updatedMerchantAdmin.flatRateCartAmount as unknown as string) || '').replace('$', ''));
    }

    try {
      const response = await updateMerchantAdmin(updatedMerchantAdmin.shopName, updatedMerchantAdmin);
      onSuccess && onSuccess(response);
      showSuccess('Successfully updated merchant settings');
      setIsSubmitting(false);

      await sendMerchantAdminUpdateMessage('merchant_settings_change', session, {
        merchantAdmin: updatedMerchantAdmin,
        dbMerchantAdmin: merchantAdmin
      });
    } catch (error: any) {
      handleError(error);
      setIsSubmitting(false);
    }
  };

  // const _pricingMode: PricingModes =
  //   merchantAdmin?.pricingMode === 'flat_rate' ? 'flat_rate' : merchantAdmin.offsetMultiplier === 1 ? 'carbon_neutral' : 'climate_positive';

  return merchantAdmin ? (
    <Formik initialValues={merchantAdmin} validationSchema={CreateValidationSchema(['flatRateCartAmount'])} onSubmit={onSubmit}>
      {({ handleSubmit, values }) => (
        <View style={Gap(5)}>
          <View className="pb-4">
            <Headings
              title="Payor Option"
              subTitle="Select who will pay the carbon offset of your orders. EcoCart collects a per-order transaction fee of $0.15 for customer paying orders and $0.03 for merchant paying orders."
            />
            <EcoRadioButtonCards
              itemStyle={{ maxWidth: 260 }}
              disabled
              dotPosition="none"
              value={payorOption}
              items={['customer_paying', 'merchant_paying'].map(hydrateRadioButtonData)}
              onValueChange={(value) => {
                setPayorOption(value as Partial<PayorMode>);
                logUserEvt('ecocart_settings', {
                  option_text: `Payor Option - ${value === 'customer_paying' ? 'Customer' : 'Merchant'}`
                });
              }}
            />
          </View>
          <View className="pb-4 shrink">
            <Headings title="Hybrid Option (optional)" subTitle="Select who will be matching your donation to offset your orders" />
            <EcoRadioButtonCards
              itemStyle={{ maxWidth: 260 }}
              disabled
              dotPosition="none"
              value={matchingOption}
              items={['no_matching', payorOption === 'customer_paying' ? 'merchant_matching' : 'customer_matching'].map(
                hydrateRadioButtonData
              )}
              onValueChange={(value) => {
                setMatchingOption(value as Partial<PayorMode> | 'no_matching');
                logUserEvt('ecocart_settings', {
                  option_text: `Hybrid Option - ${value === 'no_matching' ? 'None' : 'Matching'}`
                });
              }}
            />
          </View>

          {/* <View className="pb-4 shrink">
            <Headings title="Calculation" subTitle="Set your impact level" />
            <EcoRadioButtonCards
              itemStyle={{ maxWidth: 260 }}
              disabled
              dotPosition="none"
              value={_pricingMode}
              items={PRICING_MODES.map(hydrateRadioButtonData)}
              onValueChange={(pricingMode) => {
                setFieldValue('pricingMode', pricingMode);
                logUserEvt('ecocart_settings', {
                  option_text: `Calculation - ${pricingMode === 'calculation' ? 'Carbon Calculation' : 'Flat Rate'}`
                });
              }}
            />
          </View> */}

          {/* {values.pricingMode === 'flat_rate' ? (
            <View className="pb-4 shrink">
              <Headings title="Flat Rate Amount" subTitle="Choose an amount for your customers to offset" />
              <EcoInput
                disabled
                // mask={USD_UNDER_10_MASK}
                label="Value should be between $1.04 and $5.00"
                value={values.flatRateCartAmount as any}
                onChangeText={handleChange('flatRateCartAmount')}
                placeholder="$1.04"
                startAdornment="$"
              />
            </View>
          ) : null} */}

          {/* <View style={tailwind('pb-4 shrink')}>
            <Headings title="Widget Default" subTitle="Select the default state of your widget" />
            <EcoRadioButtonCards
              disabled
              dotPosition="none"
              value={optOption}
              items={['opt_out', 'opt_in'].map(hydrateRadioButtonData)}
              onValueChange={(value) => {
                setOptOption(value as 'opt_in' | 'opt_out');
                logUserEvt('ecocart_settings', {
                  option_text: `Widget Default - Opt ${value === 'opt_out' ? 'Out' : 'In'}`
                });
              }}
            />
          </View> */}

          {session?.merchantAdmin?.platform !== 'api' ? (
            <>
              <EcoCheckbox disabled containerStyle={{ alignItems: 'start' }} value={values.autofulfillmentMode === 'enabled'}>
                <View style={Gap(1)}>
                  <EcoText className="opacity-50" fontWeight="semibold" fontSize="sm">
                    Auto Fulfillment
                  </EcoText>
                  <EcoText className="max-w-[530px] opacity-50" fontSize="sm" fontWeight="regular">
                    {session?.merchantAdmin?.pkgProtectionEnabled
                      ? 'Enable Shopify to automatically mark the "Package Protection" product from ShipInsure as fulfilled.'
                      : 'Enable Shopify to automatically mark the "Carbon Neutral Order" product from EcoCart as fulfilled.'}
                  </EcoText>
                </View>
              </EcoCheckbox>

              <EcoCheckbox disabled containerStyle={{ alignItems: 'start' }} value={values.autofulfillmentMode === 'delayed'}>
                <View style={Gap(1)}>
                  <EcoText className="opacity-50" fontWeight="semibold" fontSize="sm">
                    Delay Fulfillment
                  </EcoText>
                  <EcoText className="max-w-[530px] opacity-50" fontSize="sm" fontWeight="regular">
                    Shopify will automatically delay fulfillment until all the other products are marked as fulfilled.
                  </EcoText>
                </View>
              </EcoCheckbox>
            </>
          ) : null}

          <FormButtons className={clsx({ 'justify-start': isWide })}>
            {/* TODO Nate! Remember to set back Disabled rules {!isValid || isSubmitting} */}
            <EcoButton nativeID="ecocart-settings-page__save-button" isDisabled={true} isLoading={isSubmitting} onPress={handleSubmit}>
              Save Changes
            </EcoButton>
          </FormButtons>
        </View>
      )}
    </Formik>
  ) : (
    <EcoLoader size="large" />
  );
}
