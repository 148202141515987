import { EcoButton, EcoCard, EcoLoader, EcoText } from '@components/shared';
import { useQuery } from '@tanstack/react-query';
import { APP_BASE_URL } from '@utils/api/api';
import { getShopifyScopes } from '@utils/api/shopify';
import { colors } from '@utils/tailwind';

interface Props {
  shopName: string;
}

export default function ShopifyScopesCard({ shopName }: Props): JSX.Element {
  const { data, isLoading: scopesAreLoading } = useQuery(['data', shopName], () => getShopifyScopes(shopName));

  const onSync = () => {
    if (!shopName) return;
    const baseUrl = `https://${shopName}/admin/oauth/authorize`;
    const clientId = '980c2450569b83493b4f8b4f1006262f';
    const scopes = data.requiredScopes.join(',');

    const authUrl = `${baseUrl}?client_id=${clientId}&scope=${scopes}&redirect_uri=${APP_BASE_URL}`;

    navigator.clipboard.writeText(authUrl);
  };
  if (!data) return <EcoLoader size="large" className="py-9" color={colors.primary[400]} />;
  return (
    <EcoCard
      style={{
        backgroundColor: data.outOfSync ? colors.danger[100] : colors.default
      }}
      title={`Shopify Scopes ${data.outOfSync ? '(Out of Sync)' : ''}`}
      topRightComponent={
        <EcoButton leftIcon={data.outOfSync ? 'content_copy' : 'check_box'} size="sm" isDisabled={!data.outOfSync} onPress={onSync}>
          {data.outOfSync ? 'Copy Link' : 'Synced'}
        </EcoButton>
      }
    >
      {scopesAreLoading ? (
        <EcoLoader size="large" className="py-9" color={colors.primary[400]} />
      ) : (
        <EcoText fontSize="base" color="subdued">
          {(data.scopes || []).join(', ')}
        </EcoText>
      )}
    </EcoCard>
  );
}
